import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const termsHtmlContent = (
  <div className="col-md-12 mx-auto">
    <h3 className="display-5">Welcome to Trustic!</h3>
    <p className="lead">
      Please review these Terms of Use before using this site or accessing any
      data on the Trustic web site. If you do not agree to these terms without
      limitation or exclusion, you should exit this site. These Terms of Use are
      effective immediately for new users, and from May 20, 2021 for existing
      users.
    </p>
    <p className="lead">
      The Trustic service (collectively, the "Service") is operated by Nextate
      Credit, LLC, a fully-owned subsidiary of Nextate Corporation ("the
      Company," "we," or "us"). By accessing or using our web site at
      http://trustic.com, including any subdomain thereof (the "Site"), you (the
      "User") signify that you have read, understand and agree to be bound by
      these terms of use ("Terms of Use"), regardless of whether you are a
      registered user of the Service. In addition to these Terms of Use, you may
      enter into other agreements with us or others that will govern your use of
      the Service or related services offered by us or others. If there is any
      contradiction between these Terms of Use and another agreement you enter
      into applicable to specific aspects of the Service, the other agreement
      shall take precedence in relation to the specific aspects of the Service
      to which it applies. As used herein, "Users" means anyone who accesses
      and/or uses the Site.
    </p>
    <h3 className="display-5">Changes to these Terms of Use</h3>
    <p className="lead">
      We may make changes to these Terms of Use from time to time. If we do
      this, we will post the changed Terms of Use on the Site and will indicate
      at the top of this page the date the Terms of Use were last revised. You
      understand and agree that your continued use of the Service or the Site
      after we have made any such changes constitutes your acceptance of the new
      Terms of Use.
    </p>
    <h3 className="display-5">ELIGIBILITY, LICENSE AND REPRESENTATIONS</h3>
    <h4>General</h4>
    <p className="lead">
      This Site is intended solely for Users who are eighteen (18) years of age
      or older, and any registration by, use of or access to the Site by any
      natural person under 18 is unauthorized, unlicensed and in violation of
      these Terms of Use. By using the Service or the Site, you represent and
      warrant that you are 18 or older and that you agree to and will abide by
      all of the terms and conditions of these Terms of Use. If you violate any
      of these Terms of Use, or otherwise violate an agreement between you and
      us, the Company may terminate your membership, delete your profile and any
      content or information that you have posted on the Site and/or prohibit
      you from using or accessing the Service or the Site (or any portion,
      aspect or feature of the Service or the Site), at any time in its sole
      discretion, with or without notice, including without limitation if it
      believes that you are under 18.
    </p>
    <h3 className="display-5">
      Proprietary Rights in Site Content; Limited License
    </h3>
    <p className="lead">
      All content on the Site, including but not limited to designs, text,
      graphics, pictures, video, information, software, music, sound and other
      files, and their selection and arrangement (the "Site Content"), are the
      proprietary property of the Company with all rights reserved. No Site
      Content may be modified, copied, distributed, framed, reproduced,
      republished, downloaded, displayed, posted, transmitted, or sold in any
      form or by any means, in whole or in part, without the Company's prior
      written permission, except as provided in the following sentence and
      except that the foregoing does not apply to your own User Content (as
      defined below) that you legally post on the Site. Provided that you are
      eligible for use of the Site, you are granted a limited license to access
      and use the Site and to download or print a copy of any portion of the
      Site Content solely for your use in connection with your use of the Site
      or Service, provided that you keep all copyright or other proprietary
      notices intact. Except for your own User Content (as defined below), you
      may not republish Site Content on any Internet, Intranet or Extranet site
      or incorporate the information in any other database or compilation, and
      any other use of the Site Content is strictly prohibited. Any use of the
      Site or the Site Content other than as specifically authorized herein,
      without the prior written permission of the Company, is strictly
      prohibited and will terminate the license granted herein. Such
      unauthorized use may also violate applicable laws including without
      limitation copyright and trademark laws and applicable communications
      regulations and statutes. Unless explicitly stated herein, nothing in
      these Terms of Use shall be construed as conferring any license to
      intellectual property rights, whether by estoppel, implication or
      otherwise. This license is revocable by us at any time without notice and
      with or without cause.
    </p>
    <h3 className="display-5">Restrictions on Data Collection/Termination</h3>
    <p className="lead">Without our prior consent, you may not:</p>
    <ul>
      <li>
        use any automated means to access this Site or collect any information
        from the Site (including, without limitation, robots, spiders, scripts
        or other automatic devices or programs);
      </li>
      <li>
        frame the Site, utilize framing techniques to enclose any Content or
        other proprietary information, place pop-up windows over this Site's
        pages, or otherwise affect the display of this Site's pages;
      </li>
      <li>
        engage in the practices of "screen scraping," "database scraping" or any
        other activity with the purpose of obtaining content or other
        information;
      </li>
      <li>
        upload, post, email, transmit or otherwise make available any
        unsolicited or unauthorized advertising, promotional materials, "junk
        mail," "spam," "chain letters," "pyramid schemes," or any other form of
        commercial or non-commercial solicitation or bulk communications of any
        kind to any Nextate User or third party;
      </li>
      <li>
        Use data provided by Trustic, provided in any manner whatsoever, for any
        competing uses or purposes. You further agree that you have never used
        data, provided in any manner whatsoever, from Trustic in the past to
        compete with the products or services of Trustic; or
      </li>
      <li>
        use this Site in any manner that violates applicable law or that could
        damage, disable, overburden, or impair this Site or interfere with any
        other party's use and enjoyment of this Site.
      </li>
    </ul>
    <h4>
      We may terminate, disable or throttle your access to, or use of, this Site
      and the Services at any time without notice for any reason, with or
      without cause at our sole discretion.
    </h4>
    <p className="lead">
      The following provisions of this Agreement shall survive termination of
      your use or access to the Site: the sections concerning Indemnification,
      Disclaimer of Warranties, Limitation of Liability, Waiver, Applicable Law
      and Dispute Resolution, and General Provisions, and any other provision
      that by its terms survives termination of your use or access to the Site.
    </p>
    <h4>User Representations</h4>
    <p className="lead">
      You represent, warrant and agree that no materials of any kind submitted
      through your account or otherwise posted or shared by you through the
      Service will violate or infringe upon the rights of any third party,
      including copyright, trademark, privacy, publicity or other personal or
      proprietary rights; or contain libelous, defamatory, fraudulent or
      otherwise unlawful material. You further agree not to harvest or collect
      email addresses or other contact information of Users from the Service or
      the Site by electronic or other means for the purposes of sending
      unsolicited emails or other unsolicited communications. Additionally, you
      agree not to use automated scripts to collect information from the Service
      or the Site or for any other purpose. You further agree that you may not
      use the Service or the Site in any unlawful manner or in any other manner
      that could damage, disable, overburden or impair the Site. In addition,
      you agree not to use the Service or the Site to:
    </p>
    <ul>
      <li>
        upload, post, transmit, share, store or otherwise make available any
        content that we deem to be harmful, threatening, unlawful, defamatory,
        infringing, abusive, inflammatory, harassing, vulgar, obscene,
        fraudulent, invasive of privacy or publicity rights, hateful, or
        racially, ethnically or otherwise objectionable;
      </li>
      <li>
        except where authorized by the Company, register for more than one User
        account, register for a User account on behalf of an individual other
        than yourself, operate a User account on behalf of or for the benefit of
        any person who is not eligible to register for or operate a User account
        in their own name, or register for a User account on behalf of any group
        or entity;
      </li>
      <li>
        impersonate any person or entity, or falsely state or otherwise
        misrepresent yourself, your age or your affiliation with or authority to
        act on behalf of any person or entity;
      </li>
      <li>
        upload, post, transmit, share or otherwise make available any
        unsolicited or unauthorized advertising, solicitations, promotional
        materials, "junk mail," "spam," "chain letters," "pyramid schemes," or
        any other form of solicitation;
      </li>
      <li>
        upload, post, transmit, share, store or otherwise make publicly
        available on the Site any private information of any third party,
        including, without limitation, addresses, phone numbers, email
        addresses, Social Security numbers and credit card numbers;
      </li>
      <li>
        solicit personal information from any natural person under 18 or solicit
        passwords or personally identifying information for commercial or
        unlawful purposes from any User;
      </li>
      <li>
        upload, post, transmit, share or otherwise make available any material
        that contains software viruses or any other malicious or tracking
        computer code, files or programs designed to track, interrupt, destroy
        or limit the functionality of any computer software or hardware or
        telecommunications equipment;
      </li>
      <li>intimidate or harass another User;</li>
      <li>
        upload, post, transmit, share, store or otherwise make available content
        that would constitute, encourage or provide instructions for a criminal
        offense, violate the rights of any party, or that would otherwise create
        liability or violate any local, state, national or international law;
      </li>
      <li>
        use or attempt to use another's account, service or system without
        authorization from that person and the Company, or create a false
        identity on the Service or the Site; or
      </li>
      <li>
        upload, post, transmit, share, store or otherwise make available content
        that, in the sole judgment of the Company, is objectionable or which
        restricts or inhibits any other person from using or enjoying the Site,
        or which may expose the Company or its Users to any harm or liability of
        any type.
      </li>
    </ul>
    <h4>User Content Posted on the Site</h4>
    <p className="lead">
      You are solely responsible for the personal or business information,
      photos, profiles, messages, notes, text, information, music, video,
      contact information for you or others, advertisements or other content
      that you upload, publish, provide or display (hereinafter, "post") on or
      through the Service or the Site, or transmit to or share with other Users
      (collectively the "User Content"). You understand and agree that the
      Company may, but is not obligated to, review and delete or remove (without
      notice) any User Content in its sole discretion, including without
      limitation, User Content that in the sole judgment of the Company violates
      these Terms of Use, might be offensive or illegal, or might violate the
      rights of, harm, or threaten the safety of, Users or others.
    </p>
    <p className="lead">
      By posting User Content to any part of the Site, you automatically grant,
      and you represent and warrant that you have the right to grant, to the
      Company an irrevocable, perpetual, non-exclusive, transferable, fully
      paid, worldwide license (with the right to sublicense) to use, copy,
      publicly perform, publicly display, reformat, translate, excerpt (in whole
      or in part) and distribute such User Content for any purpose on or in
      connection with the Site or the promotion thereof, to prepare derivative
      works of, or incorporate into other works, such User Content, and to grant
      and authorize sublicenses of the foregoing. You may remove your User
      Content from the Site at any time. If you choose to remove your User
      Content, the license granted above will not expire.
    </p>
    <h3 className="display-5">
      CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES
    </h3>
    <p className="lead">
      Because the Trustic service operates only on the Internet, it is necessary
      for you to consent to transact business with us online and electronically.
      As part of doing business with us, therefore, we also need you to consent
      to our giving you certain disclosures electronically, either via our Site
      or to the email address you provide to us. By agreeing to the Terms of
      Use, you agree to receive electronically all documents, communications,
      notices, contracts, and agreements arising from or relating to your use of
      the Site and Service, including any loans you may request, receive or
      guarantee, your registration as a borrower or guarantor on our Site, your
      use of this Service, and the servicing of your loan, if funded, as either
      a borrower or guarantor of Trustic service (each, a "Disclosure"), from
      us.
    </p>
    <p className="lead">
      The decision to do business with us electronically is yours. This document
      informs you of your rights concerning Disclosures.
    </p>
    <p className="lead">
      Use of the Site and Service is contingent on acceptance of and compliance
      with these Terms of Use by all persons acting on your behalf or in
      furtherance of your loan request, including persons acting as a
      prospective guarantor of any requested loan.
    </p>
    <p className="lead">
      All information relating to any prospective guarantor that is requested by
      any registration form on the Site will be provided via the Site directly
      by such prospective guarantor.
    </p>
    <p className="lead">
      <b>CUSTOMER COMMUNICATIONS:</b> By accepting these Terms of Use, you
      expressly consent to be contacted by us, our agents, representatives,
      affiliates, or anyone calling on our behalf for any and all purposes, at
      any telephone number, or physical or electronic address you provide or at
      which you may be reached. You agree we may contact you in any way,
      including SMS messages (including text messages), calls using pre-recorded
      messages or artificial voice, and calls and messages delivered using auto
      telephone dialing system or an automatic texting system. Automated
      messages may be played when the telephone is answered, whether by you or
      someone else. In the event that an agent or representative calls, he or
      she may also leave a message on your answering machine, voice mail, or
      send one via text.
    </p>
    <p className="lead">
      You consent to receive SMS messages (including text messages), calls and
      messages (including prerecorded and artificial voice and autodialed) from
      us, our agents, representatives, affiliates or anyone calling on our
      behalf at the specific number(s) you have provided to us, or numbers we
      can reasonably associate with your account (through skip trace, caller ID
      capture or other means), with information or questions about your
      application, loan and/or account. You certify, warrant and represent that
      the telephone numbers that you have provided to us are your numbers and
      not someone else's. You represent that you are permitted to receive calls
      at each of the telephone numbers you have provided to us. You agree to
      alert us whenever you stop using a particular telephone number.
    </p>
    <p className="lead">
      We offer you the chance to enroll to receive SMS/text messages from
      Trustic, such as account-related news and alerts and/or offers for
      products and services. By enrolling in Trustic's SMS/text messaging
      service, you agree to receive text messages from Trustic to your mobile
      phone number provided, and you certify that your mobile number provided is
      true and accurate and that you are authorized to enroll the designated
      mobile number to receive such text messages. You acknowledge and agree
      that the text messages may be sent using an automatic telephone dialing
      system and that standard message and data rates apply. Consent to receive
      SMS/text messages is not required as a condition of purchase.
    </p>
    <p className="lead">
      To unsubscribe from text messages at any time, text STOP SMS to
      888-429-0480 or reply STOP to any text message you receive from Trustic.
      You consent that following such a request to unsubscribe, you may receive
      one final text message from Trustic confirming your request. For help,
      contact us at{" "}
      <a href="mailto:compliance@trustic.com">compliance@trustic.com.</a>
    </p>
    <p className="lead">
      <b>
        TELEMARKETING CALLS: If you have provided consent for telemarketing
        calls, you agree that (i) we, our agents, representatives, affiliates,
        third parties, and anyone calling on our behalf may call, email or SMS
        messages (including text messages) you at the numbers and addresses you
        have provided for purposes of describing goods and services that may be
        of interest to you, offered by us, our affiliates and/or third parties;
        (ii) agree these calls, text and email messages may be made using an
        automatic dialing or email system technology and/or involve prerecorded
        and/or artificial voice messaging. Your consent, if provided, will be
        effective even if the number you have provided is registered on any
        state or federal Do-Not-Call (DNC) list. This consent for telemarketing
        calls shall remain in effect until you revoke it. Your consent to
        telemarketing calls may be revoked by informing the telemarketing
        representative, or any other method, which ensures we receive the
        revocation.
      </b>
    </p>
    <p className="lead">
      <b>
        Your consent to telemarketing calls is not a condition of obtaining your
        loan.
      </b>
    </p>
    <p className="lead">
      You also understand that your cellular or mobile telephone provider will
      charge you according to the type of plan you carry.
    </p>
    <p className="lead">
      <b>Opt-Out:</b> You can revoke your consent by contacting us via email at{" "}
      <a href="mailto:compliance@trustic.com">compliance@trustic.com</a> or by
      calling Support at 888-429-0480. You may also reach us in writing to us at
      the following address: 111 W Jackson Blvd, Suite 1700, Chicago, IL 60604,
      Attention: Opt-out.
    </p>
    <p className="lead">
      <b>CALL RECORDING AND MONITORING:</b> You consent to the recording and
      monitoring, for quality assurance, training, risk management and/or
      collection purposes, of any call that you place with us (or our agents,
      representatives, affiliates, third parties or anyone calling on our
      behalf) or that we (or our agents, representatives, affiliates, third
      parties or anyone calling on our behalf) place to you.
    </p>
    <p className="lead">
      <b>Electronic Communications.</b> Any Disclosures will be provided to you
      electronically through http://trustic.com either on our Site or via
      electronic mail to the email address you provided. If you require paper
      copies of such Disclosures, you may sign in to your account on our Site
      and print the documents desired or you may write to us at the mailing
      address provided below and a paper copy will be sent to you at a cost of
      up to $5.00 per document requested. A request for a paper copy of any
      Disclosure will not be considered a withdrawal of your consent to receive
      Disclosures electronically. We may discontinue electronic provision of
      Disclosures at any time in our sole discretion.
    </p>
    <p className="lead">
      Scope of Consent. Your consent to receive Disclosures and transact
      business electronically, and our agreement to do so, applies to any
      transactions to which such Disclosures relate between you and Trustic.
      Your consent will remain in effect for so long as you are a User and, if
      you are no longer a User, will continue until such a time as all
      Disclosures relevant to transactions that occurred while you were a User
      have been made.
    </p>
    <p className="lead">
      Consenting to Do Business Electronically. Before you decide to do business
      electronically with Trustic, you should consider whether you have the
      required hardware and software capabilities described below.
    </p>
    <p className="lead">
      Hardware and Software Requirements. In order to access and retain
      Disclosures electronically, you must satisfy the following computer
      hardware and software requirements: access to the Internet; an email
      account and related software capable of receiving email through the
      Internet; supported Web browsing software (Chrome version 32.0 or higher,
      Firefox version 26.0 or higher, Internet Explorer version 8.0 or higher,
      or Safari version 7.0 or higher); and hardware capable of running this
      software. To ensure access and optimal printing of your loan documents in
      PDF format, you must have Adobe Reader.
    </p>
    <p className="lead">
      <b>Additional Mobile Technology Requirements.</b> If you are accessing our
      site and the Disclosures electronically via a mobile device (such as a
      smart phone, tablet, and the like), in addition to the above requirements
      you must make sure that you have software on your mobile device that
      allows you to print and save the Disclosures presented to you during the
      application process. These applications can be found for most mobile
      devices in the device's respective "app store". If you do not have these
      capabilities on your mobile device, please access our site through a
      device that provides these capabilities.
    </p>
    <p className="lead">
      <b>Withdrawing Consent.</b> You may withdraw your consent to receive
      Disclosures electronically by contacting us at the address below. However,
      once you have withdrawn your consent you will not be able to request or
      guarantee loans on our Site. If you have a pending loan request on our
      Site we will terminate it and remove it from our system. If you are acting
      as a potential guarantor for a loan, your guarantor nomination will be
      cancelled and the borrower will have to nominate a different guarantor. If
      you have already received a loan that has been funded, all previously
      agreed to terms and conditions will remain in effect, and we will send
      Disclosures to your verified home address provided during registration. If
      you are a guarantor on a loan, all previously agreed to terms and
      conditions will remain in effect, and we will send Disclosures to your
      verified home address provided during registration.
    </p>
    <p className="lead">
      How to Contact Us regarding Electronic Disclosures. You can contact us via
      email at{" "}
      <a href="mailto:compliance@trustic.com">compliance@trustic.com</a> or by
      calling Member Support at 888-429-0480. You may also reach us in writing
      to us at the following address: 111 W Jackson Blvd, Suite 1700, Chicago,
      IL 60604, Attention: Disclosures.
    </p>
    <p className="lead">
      You will print a copy of this Agreement for your records and you agree and
      acknowledge that you can access, receive and retain all Disclosures
      electronically sent via email or posted on the Site.
    </p>
    <p className="lead">
      Change in Your Contact Information. You will keep us informed of any
      change in your email or home mailing address so that you can continue to
      receive all Disclosures in a timely fashion. You must notify us of the
      change by sending an email to{" "}
      <a href="mailto:support@trustic.com">support@trustic.com</a>
      or calling 888-429-0480. You also agree to update your registered
      residence address and telephone number on the Site if they change.
    </p>
    <p className="lead">
      If you are acting as a guarantor for a loan, you also agree to notify us
      of any change to your home address via email or telephone using the
      contact information provided above.
    </p>
    <h4>Registration Data; Account Security</h4>
    <p className="lead">
      In consideration of your use of the Site, you agree to (a) provide
      accurate, current and complete information about you as may be prompted by
      any registration forms on the Site or otherwise requested by the Company
      ("Registration Data"); (b) maintain the security of your password and
      identification; (c) maintain and promptly update the Registration Data,
      and any other information you provide to the Company, to keep it accurate,
      current and complete; (d) promptly notify the Company regarding any
      material changes to information or circumstances that could affect your
      eligibility to continue to use the Site or Service or the terms on which
      you use the Site or Service; and (e) be fully responsible for all use of
      your account and for any actions that take place using your account.
    </p>
    <h3 className="display-5">INTELLECTUAL PROPERTY MATTERS</h3>
    <h4>Trademarks</h4>
    <p className="lead">
      Trustic and other Company graphics, logos, designs, page headers, button
      icons, scripts and service names are registered trademarks, trademarks or
      trade dress of the Company in the U.S. and/or other countries. The
      Company's trademarks and trade dress may not be used, including as part of
      trademarks and/or as part of domain names, in connection with any product
      or service in any manner that is likely to cause confusion and may not be
      copied, imitated, or used, in whole or in part, without the prior written
      permission of the Company.
    </p>
    <h4>Copyright Complaints</h4>
    <p className="lead">
      If you believe that any material on the Site infringes upon any copyright
      which you own or control, you may send a written notification of such
      infringement to our Designated Agent as set forth below:
    </p>
    <p className="lead">Attention: Legal Department</p>
    <p className="lead">859 N La Salle, unit 4, Chicago, IL, 60610</p>
    <p className="lead">888-429-0480</p>
    <p className="lead">
      <a href="mailto:compliance@trustic.com">compliance@trustic.com</a>
    </p>
    <p className="lead">
      To meet the notice requirements under the Digital Millennium Copyright
      Act, the notification must be a written communication that includes the
      following:
    </p>
    <ol>
      <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
      </li>
      <li>
        Identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works at a single online site are covered by
        a single notification, a representative list of such works at that site;
      </li>
      <li>
        Identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit us to locate the material;
      </li>
      <li>
        Information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number and, if
        available, an electronic mail address at which the complaining party may
        be contacted;
      </li>
      <li>
        A statement that the complaining party has a good-faith belief that use
        of the material in the manner complained of is not authorized by the
        copyright owner, its agent or the law; and
      </li>
      <li>
        A statement that the information in the notification is accurate, and
        under penalty of perjury, that the complaining party is authorized to
        act on behalf of the owner of an exclusive right that is allegedly
        infringed.
      </li>
    </ol>
    <h4>Repeat Infringer Policy</h4>
    <p className="lead">
      In accordance with the Digital Millennium Copyright Act (DMCA) and other
      applicable law, the Company has adopted a policy of terminating, in
      appropriate circumstances and at the Company's sole discretion, the
      memberships of members who are deemed to be repeat infringers. The Company
      may also at its sole discretion limit access to the Site and/or terminate
      the memberships of any Users who infringe any intellectual property rights
      of others, whether or not there is any repeat infringement.
    </p>
    <h4>Submissions</h4>
    <p className="lead">
      You acknowledge and agree that any questions, comments, suggestions,
      ideas, feedback or other information about the Site or the Service
      ("Submissions"), provided by you to the Company are non-confidential and
      shall become the sole property of the Company. The Company shall own
      exclusive rights, including all intellectual property rights, and shall be
      entitled to the unrestricted use and dissemination of these Submissions
      for any purpose, commercial or otherwise, without acknowledgment or
      compensation to you.
    </p>
    <h3 className="display-5">MISCELLANEOUS TERMS</h3>
    <h4>Links to Other Web Sites and Content</h4>
    <p className="lead">
      The Site contains (or you may be sent through the Site or the Services)
      links to other web sites ("Third Party Sites"), as well as articles,
      photographs, text, graphics, pictures, designs, music, sound, video,
      information, software and other content belonging to or originating from
      third parties (the "Third Party Content"). Such Third Party Sites and
      Third Party Content are not investigated, monitored or checked for
      accuracy, appropriateness, or completeness by us, and we are not
      responsible for any Third Party Sites accessed through the Site or any
      Third Party Content posted on the Site, including without limitation the
      content, accuracy, offensiveness, opinions, reliability or policies of or
      contained in the Third Party Sites or the Third Party Content. Inclusion
      of or linking to any Third Party Site or any Third Party Content does not
      imply approval or endorsement thereof by us. If you decide to leave the
      Site and access the Third Party Sites, you do so at your own risk and you
      should be aware that our terms and policies no longer govern. You should
      review the applicable terms and policies, including privacy and data
      gathering practices, of any site to which you navigate from the Site.
    </p>
    <h4>User Disputes</h4>
    <p className="lead">
      You are solely responsible for your interactions with other Users,
      including persons acting as your guarantors. We reserve the right, but
      have no obligation, to monitor disputes between you and other Users.
    </p>
    <h4>Privacy</h4>
    <p className="lead">
      Please review the Site's <Link to="/privacy">Privacy Policy</Link>. By
      using the Site or the Service, you are consenting to the{" "}
      <Link to="/privacy">Privacy Policy</Link> and agree to have your personal
      data transferred to and processed in the United States and if needed,
      other countries where Trustic offices are located. We may listen to and/or
      record phone calls between you and our representatives without notice to
      you as permitted by applicable law. For example, we listen to and record
      calls for quality monitoring purposes.
    </p>
    <h4>Disclaimers</h4>
    <p className="lead">
      None of Company's, its parent, any of its affiliates, subsidiaries,
      providers or their respective officers, directors, employees, agents,
      independent contractors or licensors (collectively the "Trustic Parties")
      guarantees the accuracy, adequacy, timeliness, reliability, completeness,
      or usefulness of any of the Content and the Trustic Parties disclaim
      liability for errors or omissions in the Content.
    </p>
    <p className="lead">
      This Site and all of the Content is provided "as is" and "as available,"
      without any warranty, either express or implied, including the implied
      warranties of merchantability, fitness for a particular purpose,
      non-infringement or title. Additionally, there are no warranties as to the
      results of your use of the Content. The Trustic Parties do not warrant
      that the Site is free of viruses or other harmful components. This does
      not affect those warranties which are incapable of exclusion, restriction
      or modification under the laws applicable to this Agreement.
    </p>
    <p className="lead">
      The Company does not guarantee the accuracy of any User Content or Third
      Party Content. Although we provide rules for User conduct and postings, we
      do not control and are not responsible for what Users post on the Site and
      are not responsible for any offensive, inappropriate, obscene, unlawful or
      otherwise objectionable content you may encounter on the Site or in
      connection with any User Content or Third Party Content. The Company is
      not responsible for the conduct, whether online or offline, of any User of
      the Site or Service. The Company cannot guarantee and does not promise any
      specific results from use of the Site and/or the Service to obtain a loan.
    </p>
    <p className="lead">
      The Site and the Service may be temporarily unavailable from time to time
      for maintenance or other reasons. The Company assumes no responsibility
      for any error, omission, interruption, deletion, defect, delay in
      operation or transmission, communications line failure, theft or
      destruction or unauthorized access to, or alteration of, User
      communications. The Company is not responsible for any problems or
      technical malfunction of any telephone network or lines, computer online
      systems, servers or providers, computer equipment, software, failure of
      email or players on account of technical problems or traffic congestion on
      the Internet or on the Site or combination thereof, including injury or
      damage to Users or to any other person's computer related to or resulting
      from participating or downloading materials in connection with the Web
      and/or in connection with the Service. Under no circumstances will the
      Company be responsible for any loss or damage, including any loss or
      damage to any User Content, financial damages or lost profits, loss of
      business, or personal injury or death, resulting from anyone's use of the
      Site or the Service, any User Content or Third Party Content posted on or
      through the Site or the Service or transmitted to Users, or any
      interactions between Users of the Site, whether online or offline.
    </p>
    <p className="lead">
      The Company reserves the right to change any and all content contained in
      the Site and any Services offered through the Site at any time without
      notice. Reference to any products, services, processes or other
      information, by trade name, trademark, manufacturer, supplier or otherwise
      does not constitute or imply endorsement, sponsorship or recommendation
      thereof, or any affiliation therewith, by the Company.
    </p>
    <h4>Limitation on Liability</h4>
    <p className="lead">
      EXCEPT IN JURISDICTIONS WHERE SUCH PROVISIONS ARE RESTRICTED, IN NO EVENT
      WILL THE COMPANY OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR
      ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
      SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA
      ARISING FROM YOUR USE OF THE SITE OR THE SERVICE OR ANY OF THE SITE
      CONTENT OR OTHER MATERIALS ON OR ACCESSED THROUGH THE SITE, EVEN IF THE
      COMPANY IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p className="lead">
      NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TO THE EXTENT
      PERMITTED BY APPLICABLE LAW THE COMPANY'S LIABILITY TO YOU FOR ANY CAUSE
      WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
      LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE COMPANY FOR THE SERVICE
      DURING THE TERM OF MEMBERSHIP. IN NO CASE, WILL THE COMPANY'S LIABILITY TO
      YOU EXCEED $1000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE COMPANY
      FOR THE SERVICE, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS
      OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY
      KIND FROM THE COMPANY, REGARDLESS OF THE CAUSE OF ACTION.
    </p>
    <h4>Governing Law; Venue and Jurisdiction</h4>
    <p className="lead">
      By visiting or using the Site and/or the Service, you agree that the laws
      of the State of Delaware, without regard to any principles of conflict of
      laws that would require or permit the application of the laws of any other
      jurisdiction, will govern these Terms of Use. If you obtain a loan, the
      terms of the loan will be governed by the state of borrower’s residence to
      the extent not preempted, without regard to any principle of conflicts of
      laws that would require or permit the application of the laws of any other
      jurisdiction.
    </p>
    <h4>Indemnity</h4>
    <p className="lead">
      You agree to indemnify and hold the Company, its subsidiaries and
      affiliates, and each of their directors, officers, agents, contractors,
      partners and employees, harmless from and against any loss, liability,
      claim, demand, damages, costs and expenses, including reasonable
      attorney's fees, arising out of or in connection with your User Content,
      any Third Party Content you post or share on or through the Site, your use
      of the Service or the Site, your conduct in connection with the Service or
      the Site or with other Users of the Service or the Site, or any violation
      of these Terms of Use or of any law or the rights of any third party. The
      previous sentence shall not apply to any extent to Users while acting as
      an investor member or while registering as an investor member in
      connection with their (i) posting of User Content or (ii) use of the Site
      or Service.
    </p>
    <h4>Other</h4>
    <p className="lead">
      The failure of the Company to exercise or enforce any right or provision
      of these Terms of Use shall not constitute a waiver of such right or
      provision in that or any other instance. If any provision of these Terms
      of Use is held invalid, the remainder of these Terms of Use shall continue
      in full force and effect. If any provision of these Terms of Use shall be
      deemed unlawful, void or for any reason unenforceable, then that provision
      shall be deemed severable from these Terms of Use and shall not affect the
      validity and enforceability of any remaining provisions.
    </p>
    <h4>Questions</h4>
    <p className="lead">
      Please visit our <Link to="/hub">Q&A Hub</Link> for more information.
    </p>
  </div>
)

const Terms = () => (
  <>
    <Layout
      headerComponent={
        <header className="header pt-8 pb-4 bg-dark text-white text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <h1>Terms of Use</h1>
                <p className="lead-2 opacity-90 mt-6">
                  Last update: May 20, 2021
                </p>
              </div>
            </div>
          </div>
        </header>
      }
    >
      <section className="section py-7">
        <div className="container">
          <div className="row">{termsHtmlContent}</div>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | Terms of Use"
      description="Your questions answered about guarantor personal loans from Trustic."
    />
  </>
)

export default Terms

export { termsHtmlContent }
